<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="sideImg" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <h2 class="font-weight-bold mb-1 text-primary">
            {{ $t('pages.signUp.title') }}
          </h2>
          <p class="mb-2">{{ $t('pages.signUp.subtitle') }}</p>
          <b-form class="mt-2" @submit.prevent>
            <FormInputGroup
              v-model="userModel.username"
              :errors="v$.userModel.username.$errors"
              :label="$t('forms.labels.username')"
            />

            <FormInputGroup
              v-model="userModel.email"
              :errors="v$.userModel.email.$errors"
              :label="$t('forms.labels.email')"
            />

            <FormInputGroup
              v-model="userModel.password"
              :errors="v$.userModel.password.$errors"
              :label="$t('forms.labels.password')"
              :type="passwordFieldType"
              is-text
            >
              <template #append>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </template>
            </FormInputGroup>

            <b-button
              :disabled="!isValid"
              variant="primary"
              type="submit"
              block
              @click="onSignUp"
            >
              {{ $t('forms.buttons.signUp') }}
            </b-button>
          </b-form>

          <div class="text-center mt-2">
            <span>{{ $t('pages.signIn.isHaveAccount') }}&nbsp;</span>
            <b-link :to="{ name: 'sign_in' }">
              <span>{{ $t('forms.buttons.enter') }}</span>
            </b-link>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { useAuthQuery } from '@/store/auth'
import { ref, computed, reactive } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email as isEmail } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import sideImg from '@/assets/images/pages/login-v2.svg'
import store from '@/store'
import router from '@/router'
import FormInputGroup from '@/views/components/forms/FormInputGroup.vue'
import useToast from '@/views/components/useToast'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    FormInputGroup,
  },
  props: {
    invite: String,
  },
  setup(props) {
    const toast = useToast()
    const invite = props.invite || ''
    const passwordFieldType = ref('password')
    const passwordToggleIcon = computed(() =>
      passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    )

    const userModel = reactive({
      email: '',
      password: '',
      username: '',
      invite,
    })

    const rules = {
      userModel: {
        username: { required, $autoDirty: true },
        email: { required, isEmail, $autoDirty: true },
        password: { required, $autoDirty: true },
      },
    }
    const v$ = useVuelidate(rules, { userModel })
    const isValid = computed(() => !v$.value.$error)

    const { mutate: signUp, isLoading } = useAuthQuery.signUp()
    const { mutate: signIn } = useAuthQuery.signIn()

    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === 'password' ? 'text' : 'password'
    }

    const onSignUp = () => {
      v$.value.$touch()
      if (!isValid.value) return
      signUp(userModel, {
        onSuccess(data) {
          if (data.success) {
            signIn(userModel, {
              onSuccess(data) {
                if (data.success) {
                  $cookies.set('JWToken', data.token)
                  store.dispatch('usersStore/set', data.user)
                  router.push({ name: 'home' })
                  toast.success(JSON.stringify(data.user.email))
                } else {
                  toast.error(JSON.stringify(data.error))
                }
              },
            })
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      })
    }

    return {
      v$,
      isValid,
      isLoading,
      sideImg,
      userModel,
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
      onSignUp,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
